









































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import ViewLayoutComponent from '@/components/ViewLayoutComponent.vue';
import InfoBoxComponent from '@/components/InfoBoxComponent.vue';
import DataTableComponent from '@/components/DataTableComponent.vue';
import DataTableActionComponent from '@/components/DataTableActionComponent.vue';
import SampleTableComponent from '@/components/SampleTableComponent.vue';
import hasFeature from '@/utils/feature';

@Component({
  components: {
    ViewLayoutComponent,
    InfoBoxComponent,
    DataTableComponent,
    DataTableActionComponent,
    SampleTableComponent,
  },
})
export default class WeightingView extends Vue {
  showEditVariables : boolean = hasFeature('variablesConfig');

  onEditVariables() {
    this.$router.push({
      name: 'edit-sample-variables',
      params: { from: 'weighting' },
    });
  }

  onBack() {
    this.$router.push('/sample');
  }

  onNext() {
    this.$router.push('/analysis');
  }
}
